<template>
  <div>
    <div style="display: flex;padding: 24rem">
      <el-button size="small" style="height: 32rem;margin-right: 24rem" @click="onDownload">下载模板</el-button>
      <div>
        <el-upload
            :action="$_axios2.defaults.baseURL + '/api/tool/stay-down/import'"
            :before-upload="beforeUpload"
            :headers="{ authorization: 'Bearer ' + $store.getters.token }"
            :on-success="onSuccess"
            :show-file-list="false"
            accept=".xlsx,.xls"
            class="upload-demo"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </div>
    </div>
    <div v-for="i in errorStr" style="font-size: 14rem;color: red;padding: 4rem 24rem">{{ i }}</div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  data() {
    return {
      data: {
        admin_menu: [],
        teaching_menu: [],
      },
      defaultProps: {
        children: 'child',
        label: 'name',
      },
      errorStr: [],
    };
  },

  methods: {
    onSuccess(response) {
      this.errorStr = [];
      if (response.status === 0) {
        this.$message.success(response.msg);
      } else {
        this.errorStr = response.data;
        this.$message.error(response.msg);
      }
    },

    beforeUpload(file) {
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const extension = testmsg === 'xls';
      const extension2 = testmsg === 'xlsx';
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是xls、xlsx格式!',
          type: 'warning',
        });
      }
      return extension || extension2;
    },
    onDownload() {
      location.href = process.env.VUE_APP_URL_PROD_BASE + '/template/stay_down.xlsx';
    },
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.div-btn {
  margin-bottom: 24rem;
}
</style>
